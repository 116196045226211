import Plugin from '@ckeditor/ckeditor5-core/src/plugin'

// ADDED 2 imports
import {
  toWidget,
  toWidgetEditable,
} from '@ckeditor/ckeditor5-widget/src/utils'
import Widget from '@ckeditor/ckeditor5-widget/src/widget'

import InsertArticolCommand from './articolcommand'

import './theme/articol.css'

export default class ArticolEditing extends Plugin {
  static get requires() {
    return [Widget]
  }

  init() {
    this._defineSchema()
    this._defineConverters()

    this.editor.commands.add(
      'insertArticol',
      new InsertArticolCommand(this.editor)
    )
  }

  _defineSchema() {
    const schema = this.editor.model.schema

    schema.register('articol', {
      // Behaves like a self-contained object (e.g. an image).
      isObject: true,

      // Allow in places where other blocks are allowed (e.g. directly in the root).
      allowIn: 'capitolDescription',
      allowAttributes: ['style', 'data-index'],
    })

    schema.register('articolDescription', {
      // Cannot be split or left by the caret.
      isLimit: true,
      allowIn: 'articol',

      // Allow content which is allowed in the root (e.g. paragraphs).
      allowContentOf: '$root',
    })

    schema.addChildCheck((context, childDefinition) => {
      if (
        context.endsWith('articolDescription') &&
        ['paragraph', 'listItem', 'tabel-terenuri'].indexOf(
          childDefinition.name
        ) < 0
      ) {
        return false
      }
    })
  }

  _defineConverters() {
    const conversion = this.editor.conversion

    // <articol> converters
    conversion.for('upcast').elementToElement({
      model: 'articol',
      view: {
        name: 'section',
        classes: 'articol',
      },
    })
    conversion.for('dataDowncast').elementToElement({
      model: 'articol',
      view: {
        name: 'section',
        classes: 'articol',
      },
    })
    conversion.for('editingDowncast').elementToElement({
      model: 'articol',
      view: (modelElement, { writer: viewWriter }) => {
        const section = viewWriter.createContainerElement('section', {
          class: 'articol',
        })

        return toWidget(section, viewWriter, { label: 'articol widget' })
      },
    })

    // <articolDescription> converters
    conversion.for('upcast').elementToElement({
      model: 'articolDescription',
      view: {
        name: 'div',
        classes: 'articol-description',
      },
    })
    conversion.for('dataDowncast').elementToElement({
      model: 'articolDescription',
      view: {
        name: 'div',
        classes: 'articol-description',
      },
    })
    conversion.for('editingDowncast').elementToElement({
      model: 'articolDescription',
      view: (modelElement, { writer: viewWriter }) => {
        const div = viewWriter.createEditableElement('div', {
          class: 'articol-description',
          'data-index': '',
          // style: `--index:'Art. ${artNo}';`,
        })

        return toWidgetEditable(div, viewWriter)
      },
    })
  }
}
