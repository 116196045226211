import Command from '@ckeditor/ckeditor5-core/src/command'
import { arabicToRoman } from './utils'

export default class InsertCapitolCommand extends Command {
  execute() {
    this.editor.model.change((writer) => {
      // Insert <capitol>*</capitol> at the current selection position
      // in a way that will result in creating a valid model structure.

      this.editor.model.insertContent(createCapitol(writer))
    })
  }

  refresh() {
    const model = this.editor.model
    const selection = model.document.selection
    const allowedIn = model.schema.findAllowedParent(
      selection.getFirstPosition(),
      'capitol'
    )

    setTimeout(() => {
      const capNo = document.querySelectorAll('.capitol-title')
      capNo.forEach((el, index) => {
        el.dataset.index = `Capitolul ${arabicToRoman(index + 1)}.`
      })
    }, 100)

    this.isEnabled = allowedIn !== null
  }
}

function createCapitol(writer) {
  const capitol = writer.createElement('capitol')
  const capitolTitle = writer.createElement('capitolTitle')
  const capitolDescription = writer.createElement('capitolDescription')
  const articol = writer.createElement('articol')
  const articolDescription = writer.createElement('articolDescription')
  const paragraph = writer.createElement('paragraph')

  writer.append(articolDescription, articol)
  writer.append(paragraph, articolDescription)
  writer.append(articol, capitolDescription)
  writer.append(capitolTitle, capitol)
  writer.append(capitolDescription, capitol)

  return capitol
}
