import Command from '@ckeditor/ckeditor5-core/src/command'

export default class ShortcodeCommand extends Command {
  execute({ value }) {
    const editor = this.editor

    editor.model.change((writer) => {
      // Create a <shortcode> elment with the "name" attribute...
      const shortcode = writer.createElement('shortcode', { name: value })

      // ... and insert it into the document.
      editor.model.insertContent(shortcode)

      // Put the selection on the inserted element.
      writer.setSelection(shortcode, 'on')
    })
  }

  refresh() {
    const model = this.editor.model
    const selection = model.document.selection

    const isAllowed = model.schema.checkChild(
      selection.focus.parent,
      'shortcode'
    )

    this.isEnabled = isAllowed
  }
}
