import PlatiEditing from './platiEditing'
import PlatiUI from './platiUI'
import Plugin from '@ckeditor/ckeditor5-core/src/plugin'

export default class Plati extends Plugin {
  static get requires() {
    return [PlatiEditing, PlatiUI]
  }

  static get pluginName() {
    return 'Plati'
  }
}
