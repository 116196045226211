import Command from '@ckeditor/ckeditor5-core/src/command'

export default class InsertSectiuneCommand extends Command {
  execute() {
    this.editor.model.change((writer) => {
      // Insert <sectiune>*</sectiune> at the current selection position
      // in a way that will result in creating a valid model structure.
      this.editor.model.insertContent(createSectiune(writer))
    })
  }

  refresh() {
    const model = this.editor.model
    const selection = model.document.selection
    const allowedIn = model.schema.findAllowedParent(
      selection.getFirstPosition(),
      'sectiune'
    )

    this.isEnabled = allowedIn !== null
  }
}

function createSectiune(writer) {
  const sectiune = writer.createElement('sectiune')
  const sectiuneTitle = writer.createElement('sectiuneTitle')
  const sectiuneDescription = writer.createElement('sectiuneDescription')
  const paragraph = writer.createElement('paragraph')
  
  writer.insertText('Titlu sectiune', sectiuneTitle)

  writer.append(paragraph, sectiuneDescription)
  writer.append(sectiuneTitle, sectiune)
  writer.append(sectiuneDescription, sectiune)

  return sectiune
}
