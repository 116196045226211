import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import { arabicToRoman } from './utils'

// ADDED 2 imports
import {
  toWidget,
  toWidgetEditable,
} from '@ckeditor/ckeditor5-widget/src/utils'
import Widget from '@ckeditor/ckeditor5-widget/src/widget'

import InsertCapitolCommand from './capitolcommand'

import './theme/capitol.css'

export default class CapitolEditing extends Plugin {
  static get requires() {
    return [Widget]
  }

  init() {
    this._defineSchema()
    this._defineConverters()

    this.editor.commands.add(
      'insertCapitol',
      new InsertCapitolCommand(this.editor)
    )
  }

  _defineSchema() {
    const schema = this.editor.model.schema

    schema.register('capitol', {
      // Behaves like a self-contained object (e.g. an image).
      isObject: true,
      isLimit: true,

      // Allow in places where other blocks are allowed (e.g. directly in the root).
      allowIn: '$root',
    })

    schema.register('capitolTitle', {
      // Cannot be split or left by the caret.
      isLimit: true,
      isBlock: true,

      allowIn: 'capitol',
      allowAttributes: ['style', 'data-index'],

      // Allow content which is allowed in blocks (i.e. text with attributes).
      allowContentOf: '$block',
    })

    schema.register('capitolDescription', {
      // Cannot be split or left by the caret.
      isLimit: true,
      allowIn: 'capitol',
      // Allow content which is allowed in the root (e.g. paragraphs).
      allowContentOf: '$root',
    })

    schema.addChildCheck((context, childDefinition) => {
      if (
        context.endsWith('capitolDescription') &&
        ['articol', 'paragraph'].indexOf(childDefinition.name) < 0
      ) {
        return false
      }
    })
  }

  _defineConverters() {
    const conversion = this.editor.conversion

    // <capitol> converters
    conversion.for('upcast').elementToElement({
      model: 'capitol',
      view: {
        name: 'section',
        classes: 'capitol',
      },
    })
    conversion.for('dataDowncast').elementToElement({
      model: 'capitol',
      view: {
        name: 'section',
        classes: 'capitol',
      },
    })
    conversion.for('editingDowncast').elementToElement({
      model: 'capitol',
      view: (modelElement, { writer: viewWriter }) => {
        const section = viewWriter.createContainerElement('section', {
          class: 'capitol',
        })

        return toWidget(section, viewWriter, { label: 'capitol widget' })
      },
    })

    // <capitolTitle> converters
    conversion.for('upcast').elementToElement({
      model: 'capitolTitle',
      view: {
        name: 'h1',
        classes: 'capitol-title',
      },
    })
    conversion.for('dataDowncast').elementToElement({
      model: 'capitolTitle',
      view: {
        name: 'h1',
        classes: 'capitol-title',
      },
    })
    conversion.for('editingDowncast').elementToElement({
      model: 'capitolTitle',
      view: (modelElement, { writer: viewWriter }) => {
        const capNo = document.querySelectorAll('.capitol').length + 1
        console.log('>>>CAP>>>', capNo)

        // Note: You use a more specialized createEditableElement() method here.
        const h1 = viewWriter.createEditableElement('h1', {
          class: 'capitol-title',
          'data-index': `Capitolul ${arabicToRoman(capNo)}.`,
        })

        return toWidgetEditable(h1, viewWriter)
      },
    })

    // <capitolDescription> converters
    conversion.for('upcast').elementToElement({
      model: 'capitolDescription',
      view: {
        name: 'div',
        classes: 'capitol-description',
      },
    })
    conversion.for('dataDowncast').elementToElement({
      model: 'capitolDescription',
      view: {
        name: 'div',
        classes: 'capitol-description',
      },
    })
    conversion.for('editingDowncast').elementToElement({
      model: 'capitolDescription',
      view: (modelElement, { writer: viewWriter }) => {
        // Note: You use a more specialized createEditableElement() method here.
        const div = viewWriter.createEditableElement('div', {
          class: 'capitol-description',
        })

        return toWidgetEditable(div, viewWriter)
      },
    })
  }
}
