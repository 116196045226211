import Command from '@ckeditor/ckeditor5-core/src/command'

export default class InsertPreturiCommand extends Command {
  execute() {
    this.editor.model.change((writer) => {
      // Insert <preturi>*</preturi> at the current selection position
      // in a way that will result in creating a valid model structure.
      this.editor.model.insertContent(createPreturi(writer))
    })
  }

  refresh() {
    const model = this.editor.model
    const selection = model.document.selection
    const allowedIn = model.schema.findAllowedParent(
      selection.getFirstPosition(),
      'preturi'
    )

    this.isEnabled = allowedIn !== null
  }
}

function createPreturi(writer) {
  return writer.createElement('preturi')
}
