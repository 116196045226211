import Plugin from '@ckeditor/ckeditor5-core/src/plugin'

// ADDED 2 imports
import { toWidget } from '@ckeditor/ckeditor5-widget/src/utils'
import Widget from '@ckeditor/ckeditor5-widget/src/widget'

import PlatiCommand from './platiCommand'

import './theme/plati.css'

export default class PlatiEditing extends Plugin {
  static get requires() {
    return [Widget]
  }

  init() {
    this._defineSchema()
    this._defineConverters()

    this.editor.commands.add('insertPlati', new PlatiCommand(this.editor))
  }

  _defineSchema() {
    const schema = this.editor.model.schema

    schema.register('plati', {
      // Behaves like a self-contained object (e.g. an image).
      isObject: true,
      isLimit: true,

      // Allow in places where other blocks are allowed (e.g. directly in the root).
      allowIn: '$root',
    })
  }

  _defineConverters() {
    const conversion = this.editor.conversion

    // <tabel-terenuri> converters
    conversion.for('upcast').elementToElement({
      model: 'plati',
      view: {
        name: 'section',
        classes: 'plati',
      },
    })
    conversion.for('dataDowncast').elementToElement({
      model: 'plati',
      view: {
        name: 'section',
        classes: 'plati',
      },
    })
    conversion.for('editingDowncast').elementToElement({
      model: 'plati',
      view: (modelElement, { writer: viewWriter }) => {
        const section = viewWriter.createContainerElement('section', {
          class: 'plati',
        })

        return toWidget(section, viewWriter, { label: 'plati widget' })
      },
    })
  }
}
