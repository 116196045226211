import Plugin from '@ckeditor/ckeditor5-core/src/plugin'

import ShortcodeEditing from './shortcodeediting'
import ShortcodeUI from './shortcodeui'

export default class Shortcode extends Plugin {
  static get requires() {
    return [ShortcodeEditing, ShortcodeUI]
  }
}
