import SemnaturiEditing from './semnaturiEditing'
import SemnaturiUI from './semnaturiUI'
import Plugin from '@ckeditor/ckeditor5-core/src/plugin'

export default class Semnaturi extends Plugin {
  static get requires() {
    return [SemnaturiEditing, SemnaturiUI]
  }

  static get pluginName() {
		return 'Semnaturi';
	}
}
