import Command from '@ckeditor/ckeditor5-core/src/command'

export default class InsertPlatiCommand extends Command {
  execute() {
    this.editor.model.change((writer) => {
      // Insert <plati>*</plati> at the current selection position
      // in a way that will result in creating a valid model structure.
      this.editor.model.insertContent(createPlati(writer))
    })
  }

  refresh() {
    const model = this.editor.model
    const selection = model.document.selection
    const allowedIn = model.schema.findAllowedParent(
      selection.getFirstPosition(),
      'plati'
    )

    this.isEnabled = allowedIn !== null
  }
}

function createPlati(writer) {
  return writer.createElement('plati')
}
