import Plugin from '@ckeditor/ckeditor5-core/src/plugin'

// ADDED 2 imports
import {
  toWidget,
  toWidgetEditable,
} from '@ckeditor/ckeditor5-widget/src/utils'
import Widget from '@ckeditor/ckeditor5-widget/src/widget'

import InsertSectiuneCommand from './sectiunecommand'

import './theme/sectiune.css'

export default class SectiuneEditing extends Plugin {
  static get requires() {
    return [Widget]
  }

  init() {
    this._defineSchema()
    this._defineConverters()

    this.editor.commands.add(
      'insertSectiune',
      new InsertSectiuneCommand(this.editor)
    )
  }

  _defineSchema() {
    const schema = this.editor.model.schema

    schema.register('sectiune', {
      // Behaves like a self-contained object (e.g. an image).
      isObject: true,
      isLimit: true,

      // Allow in places where other blocks are allowed (e.g. directly in the root).
      allowIn: '$root',
    })

    schema.register('sectiuneTitle', {
      // Cannot be split or left by the caret.
      isLimit: true,
      isBlock: true,

      allowIn: 'sectiune',

      // Allow content which is allowed in blocks (i.e. text with attributes).
      allowContentOf: '$block',
    })

    schema.register('sectiuneDescription', {
      // Cannot be split or left by the caret.
      isLimit: true,
      allowIn: 'sectiune',
      // Allow content which is allowed in the root (e.g. paragraphs).
      allowContentOf: '$root',
    })

    schema.addChildCheck((context, childDefinition) => {
      if (
        context.endsWith('sectiuneDescription') &&
        ['paragraph', 'heading2', 'heading3', 'heading4',  'listItem', 'table'].indexOf(childDefinition.name) < 0
      ) {
        return false
      }
    })
  }

  _defineConverters() {
    const conversion = this.editor.conversion

    // <sectiune> converters
    conversion.for('upcast').elementToElement({
      model: 'sectiune',
      view: {
        name: 'section',
        classes: 'generic-section',
      },
    })
    conversion.for('dataDowncast').elementToElement({
      model: 'sectiune',
      view: {
        name: 'section',
        classes: 'generic-section',
      },
    })
    conversion.for('editingDowncast').elementToElement({
      model: 'sectiune',
      view: (modelElement, { writer: viewWriter }) => {
        const section = viewWriter.createContainerElement('section', {
          class: 'generic-section',
        })

        return toWidget(section, viewWriter, { label: 'sectiune widget' })
      },
    })

    // <sectiuneTitle> converters
    conversion.for('upcast').elementToElement({
      model: 'sectiuneTitle',
      view: {
        name: 'h1',
        classes: 'generic-section-title',
      },
    })
    conversion.for('dataDowncast').elementToElement({
      model: 'sectiuneTitle',
      view: {
        name: 'h1',
        classes: 'generic-section-title',
      },
    })
    conversion.for('editingDowncast').elementToElement({
      model: 'sectiuneTitle',
      view: (modelElement, { writer: viewWriter }) => {
        // Note: You use a more specialized createEditableElement() method here.
        const h1 = viewWriter.createEditableElement('h1', {
          class: 'generic-section-title'
        })

        return toWidgetEditable(h1, viewWriter)
      },
    })

    // <sectiuneDescription> converters
    conversion.for('upcast').elementToElement({
      model: 'sectiuneDescription',
      view: {
        name: 'div',
        classes: 'generic-section-description',
      },
    })
    conversion.for('dataDowncast').elementToElement({
      model: 'sectiuneDescription',
      view: {
        name: 'div',
        classes: 'generic-section-description',
      },
    })
    conversion.for('editingDowncast').elementToElement({
      model: 'sectiuneDescription',
      view: (modelElement, { writer: viewWriter }) => {
        // Note: You use a more specialized createEditableElement() method here.
        const div = viewWriter.createEditableElement('div', {
          class: 'generic-section-description',
        })

        return toWidgetEditable(div, viewWriter)
      },
    })
  }
}
