import Plugin from '@ckeditor/ckeditor5-core/src/plugin'

// ADDED 2 imports
import {
  toWidget,
  viewToModelPositionOutsideModelElement,
} from '@ckeditor/ckeditor5-widget/src/utils'
import Widget from '@ckeditor/ckeditor5-widget/src/widget'
import ShortcodeCommand from './shortcodecommand'

import './theme/shortcode.css'

export default class ShortcodeEditing extends Plugin {
  static get requires() {
    // ADDED
    return [Widget]
  }

  init() {
    this._defineSchema() // ADDED
    this._defineConverters()

    // ADDED
    this.editor.commands.add('shortcode', new ShortcodeCommand(this.editor))

    this.editor.editing.mapper.on(
      'viewToModelPosition',
      viewToModelPositionOutsideModelElement(this.editor.model, (viewElement) =>
        viewElement.hasClass('shortcode')
      )
    )

    this.editor.config.define('shortcodeConfig', {
      // ADDED
      types: ['date', 'first name', 'surname'],
    })
  }

  _defineSchema() {
    // ADDED
    const schema = this.editor.model.schema

    schema.register('shortcode', {
      // Allow wherever text is allowed:
      allowWhere: '$text',

      // The placeholder will act as an inline node:
      isInline: true,

      // The inline widget is self-contained so it cannot be split by the caret and can be selected:
      isObject: true,

      // The placeholder can have many types, like date, name, surname, etc:
      allowAttributes: ['name'],
    })
  }

  _defineConverters() {
    // ADDED
    const conversion = this.editor.conversion

    conversion.for('upcast').elementToElement({
      view: {
        name: 'span',
        classes: ['shortcode'],
      },
      model: (viewElement, { writer: modelWriter }) => {
        // Extract the "name" from "{name}".
        const name = viewElement.getChild(0).data.slice(1, -1)

        return modelWriter.createElement('shortcode', { name })
      },
    })

    conversion.for('editingDowncast').elementToElement({
      model: 'shortcode',
      view: (modelItem, { writer: viewWriter }) => {
        const widgetElement = createShortcodeView(modelItem, viewWriter)

        // Enable widget handling on a shortcode element inside the editing view.
        return toWidget(widgetElement, viewWriter)
      },
    })

    conversion.for('dataDowncast').elementToElement({
      model: 'shortcode',
      view: (modelItem, { writer: viewWriter }) =>
        createShortcodeView(modelItem, viewWriter),
    })

    // Helper method for both downcast converters.
    function createShortcodeView(modelItem, viewWriter) {
      const name = modelItem.getAttribute('name')

      const shortcodeView = viewWriter.createContainerElement('span', {
        class: 'shortcode',
      })

      // Insert the shortcode name (as a text).
      const innerText = viewWriter.createText('{' + name + '}')
      viewWriter.insert(
        viewWriter.createPositionAt(shortcodeView, 0),
        innerText
      )

      return shortcodeView
    }
  }
}
