import TabelTerenuriEditing from './tabelTerenuriEditing'
import TabelTerenuriUI from './tabelTerenuriUI'
import Plugin from '@ckeditor/ckeditor5-core/src/plugin'

export default class TabelTerenuri extends Plugin {
  static get requires() {
    return [TabelTerenuriEditing, TabelTerenuriUI]
  }

  static get pluginName() {
		return 'TabelTerenuri';
	}
}
