import Plugin from '@ckeditor/ckeditor5-core/src/plugin'

import {
  addListToDropdown,
  createDropdown,
} from '@ckeditor/ckeditor5-ui/src/dropdown/utils'
import Collection from '@ckeditor/ckeditor5-utils/src/collection'
import Model from '@ckeditor/ckeditor5-ui/src/model'

export default class ShortcodeUI extends Plugin {
  init() {
    const editor = this.editor
    const t = editor.t
    const shortcodeNames = editor.config.get('placeholderConfig.types')

    // The "shortcode" dropdown must be registered among the UI components of the editor
    // to be displayed in the toolbar.
    editor.ui.componentFactory.add('shortcode', (locale) => {
      const dropdownView = createDropdown(locale)

      // Populate the list in the dropdown with items.
      addListToDropdown(
        dropdownView,
        getDropdownItemsDefinitions(shortcodeNames)
      )

      dropdownView.buttonView.set({
        // The t() function helps localize the editor. All strings enclosed in t() can be
        // translated and change when the language of the editor changes.
        label: t('Shortcode'),
        tooltip: true,
        withText: true,
      })

      // Disable the placeholder button when the command is disabled.
      const command = editor.commands.get('shortcode')
      dropdownView.bind('isEnabled').to(command)

      // Execute the command when the dropdown item is clicked (executed).
      this.listenTo(dropdownView, 'execute', (evt) => {
        editor.execute('shortcode', { value: evt.source.commandParam })
        editor.editing.view.focus()
      })

      return dropdownView
    })
  }
}

function getDropdownItemsDefinitions(shortcodeNames) {
  const itemDefinitions = new Collection()

  for (const name of shortcodeNames) {
    const definition = {
      type: 'button',
      model: new Model({
        commandParam: name,
        label: name,
        withText: true,
      }),
    }

    // Add the item definition to the collection.
    itemDefinitions.add(definition)
  }

  return itemDefinitions
}
