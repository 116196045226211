import ArticolEditing from './articolediting'
import ArticolUI from './articolui'
import Plugin from '@ckeditor/ckeditor5-core/src/plugin'

export default class Articol extends Plugin {
  static get requires() {
    return [ArticolEditing, ArticolUI]
  }

  static get pluginName() {
		return 'Articol';
	}
}
