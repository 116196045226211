import Command from '@ckeditor/ckeditor5-core/src/command'

export default class InsertArticolCommand extends Command {
  constructor(editor) {
    super(editor)

    this._selectedElements = []

    // Refresh command before executing to be sure all values are up to date.
    // It is needed when selection has changed before command execution, in the same change block.
    this.on(
      'execute',
      () => {
        this.refresh()
      },
      { priority: 'highest' }
    )
  }

  execute(options = {}) {
    const editor = this.editor
    const model = editor.model
    const doc = model.document

    model.change((writer) => {
      this.editor.model.insertContent(
        createArticol(writer, this._selectedElements[0])
      )
    })
  }

  refresh() {
    this._selectedElements = this._getSelectedItems()
    const model = this.editor.model
    const selection = model.document.selection
    const allowedIn = model.schema.findAllowedParent(
      selection.getFirstPosition(),
      'articol'
    )

    setTimeout(() => {
      const artNo = document.querySelectorAll('.articol-description')
      artNo.forEach((el, index) => {
        // el.setAttribute('style', `--index:'Art. ${index + 1}';`);
        el.childNodes[0].dataset.index = `Art. ${index + 1}`
      })
    }, 0)

    this.isEnabled = allowedIn !== null
  }

  _getSelectedItems() {
    const model = this.editor.model
    const schema = model.schema

    const selectionRange = model.document.selection.getFirstRange()
    const startElement = selectionRange.start.parent
    const elements = []

    // elements.push(startElement)

    for (const item of selectionRange.getItems()) {
      elements.push(item)
    }

    return elements
  }
}

function createArticol(writer, blocks) {
  const articol = writer.createElement('articol')
  const articolDescription = writer.createElement('articolDescription')

  writer.append(articolDescription, articol)

  // writer.append(blocks[0], articolDescription)

  // blocks.forEach((bl) => {
  //   console.log(bl.is('element', 'paragraph'))
  // })

  // There must be at least one paragraph for the description to be editable.
  // See https://github.com/ckeditor/ckeditor5/issues/1464.
  writer.appendElement('paragraph', articolDescription)
  //writer.appendText(blocks.data, articolDescription)

  return articol
}
