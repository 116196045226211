import PreturiEditing from './preturiEditing'
import PreturiUI from './preturiUI'
import Plugin from '@ckeditor/ckeditor5-core/src/plugin'

export default class Preturi extends Plugin {
  static get requires() {
    return [PreturiEditing, PreturiUI]
  }

  static get pluginName() {
    return 'Preturi'
  }
}
