import Plugin from '@ckeditor/ckeditor5-core/src/plugin'

// ADDED 2 imports
import {
  toWidget,
  toWidgetEditable,
} from '@ckeditor/ckeditor5-widget/src/utils'
import Widget from '@ckeditor/ckeditor5-widget/src/widget'

import TabelTerenuriCommand from './tabelTerenuriCommand'

import './theme/tabelTerenuri.css'

export default class TabelTerenuriEditing extends Plugin {
  static get requires() {
    return [Widget]
  }

  init() {
    this._defineSchema()
    this._defineConverters()

    this.editor.commands.add(
      'insertTabelTerenuri',
      new TabelTerenuriCommand(this.editor)
    )
  }

  _defineSchema() {
    const schema = this.editor.model.schema

    schema.register('tabel-terenuri', {
      // Behaves like a self-contained object (e.g. an image).
      isObject: true,
      isLimit: true,

      // Allow in places where other blocks are allowed (e.g. directly in the root).
      allowIn: ['$root', 'articol'],
    })
  }

  _defineConverters() {
    const conversion = this.editor.conversion

    // <tabel-terenuri> converters
    conversion.for('upcast').elementToElement({
      model: 'tabel-terenuri',
      view: {
        name: 'section',
        classes: 'tabel-terenuri',
      },
    })
    conversion.for('dataDowncast').elementToElement({
      model: 'tabel-terenuri',
      view: {
        name: 'section',
        classes: 'tabel-terenuri',
      },
    })
    conversion.for('editingDowncast').elementToElement({
      model: 'tabel-terenuri',
      view: (modelElement, { writer: viewWriter }) => {
        const section = viewWriter.createContainerElement('section', {
          class: 'tabel-terenuri',
        })

        return toWidget(section, viewWriter, { label: 'tabel-terenuri widget' })
      },
    })
  }
}
