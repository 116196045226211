import DecoupledDocumentEditor from '@ckeditor/ckeditor5-editor-decoupled/src/decouplededitor.js'
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials'
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import List from '@ckeditor/ckeditor5-list/src/list'
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle.js'
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold'
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic'
import Table from '@ckeditor/ckeditor5-table/src/table.js'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment.js'
import PageBreak from '@ckeditor/ckeditor5-page-break/src/pagebreak.js'
import ExportToPDF from '@ckeditor/ckeditor5-export-pdf/src/exportpdf.js'

import Shortcode from './shortcode/shortcode'
import Capitol from './capitol/capitol'
import Articol from './articol/articol'
import Sectiune from './sectiune/sectiune'
import TabelTerenuri from './tabelTerenuri/tabelTerenuri'
import Semnaturi from './semnaturi/semnaturi'
import Plati from './plati/plati'
import Preturi from './preturi/preturi'

class Editor extends DecoupledDocumentEditor {}

Editor.builtinPlugins = [
  Essentials,
  Paragraph,
  Heading,
  List,
  ListStyle,
  Bold,
  Italic,
  Shortcode,
  Table,
  TableToolbar,
  Capitol,
  Articol,
  Alignment,
  Sectiune,
  PageBreak,
  TabelTerenuri,
  Semnaturi,
  ExportToPDF,
  Plati,
  Preturi,
]

export default { Editor }
