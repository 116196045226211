import CapitolEditing from './capitolediting'
import CapitolUI from './capitolui'
import Plugin from '@ckeditor/ckeditor5-core/src/plugin'

export default class Capitol extends Plugin {
  static get requires() {
    return [CapitolEditing, CapitolUI]
  }

  static get pluginName() {
		return 'Capitol';
	}
}
