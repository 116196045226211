import SectiuneEditing from './sectiuneediting'
import SectiuneUI from './sectiuneui'
import Plugin from '@ckeditor/ckeditor5-core/src/plugin'

export default class Sectiune extends Plugin {
  static get requires() {
    return [SectiuneEditing, SectiuneUI]
  }

  static get pluginName() {
		return 'Sectiune';
	}
}
