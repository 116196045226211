import Plugin from '@ckeditor/ckeditor5-core/src/plugin'

// ADDED 2 imports
import { toWidget } from '@ckeditor/ckeditor5-widget/src/utils'
import Widget from '@ckeditor/ckeditor5-widget/src/widget'

import PreturiCommand from './preturiCommand'

import './theme/preturi.css'

export default class PreturiEditing extends Plugin {
  static get requires() {
    return [Widget]
  }

  init() {
    this._defineSchema()
    this._defineConverters()

    this.editor.commands.add('insertPreturi', new PreturiCommand(this.editor))
  }

  _defineSchema() {
    const schema = this.editor.model.schema

    schema.register('preturi', {
      // Behaves like a self-contained object (e.g. an image).
      isObject: true,
      isLimit: true,

      // Allow in places where other blocks are allowed (e.g. directly in the root).
      allowIn: '$root',
    })
  }

  _defineConverters() {
    const conversion = this.editor.conversion

    // <tabel-terenuri> converters
    conversion.for('upcast').elementToElement({
      model: 'preturi',
      view: {
        name: 'section',
        classes: 'preturi',
      },
    })
    conversion.for('dataDowncast').elementToElement({
      model: 'preturi',
      view: {
        name: 'section',
        classes: 'preturi',
      },
    })
    conversion.for('editingDowncast').elementToElement({
      model: 'preturi',
      view: (modelElement, { writer: viewWriter }) => {
        const section = viewWriter.createContainerElement('section', {
          class: 'preturi',
        })

        return toWidget(section, viewWriter, { label: 'preturi widget' })
      },
    })
  }
}
